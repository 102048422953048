import React, { useContext } from "react";
import { Col, Divider, Image, Row, Space, Typography } from "antd";
import AppLayout from "../layouts/AppLayout";
import { AppContext, IAppState } from "../contexts/AppContextProvider";
import { ContactDateL1, ContactDateL2, ContactDateL3, ContactLocalizationv2, ContactLocalizationv2Value, ContactName, ContactPhone, ContactTitle, HomeDescription2, HomeTitle1, HomeTitle1Description, HomeTitle2, PicturesTitle, TeamsDescription, TeamsTitle } from "../translations/Translator";

interface IHomePageProps {}

const { Title, Text, Paragraph, Link } = Typography;
const googleMapUrl: string = "https://www.google.fr/search?tbs=lf:1,lf_ui:2&tbm=lcl&q=kiddy+joy+nursery+douala&rflfq=1&num=10&ved=2ahUKEwj4na2w0OvyAhWuNOwKHQlqDdEQtgN6BAgJEAM#rlfi=hd:;si:5130715132676660901;mv:[[4.058182592185646,9.78497359965976],[4.044098722209823,9.7610268375748],null,[4.051140687846341,9.77300021861728],16]";
const HomePage: React.FunctionComponent<IHomePageProps> = (props) => {
  const { AppState } = useContext(AppContext);
  const App: IAppState = AppState;
  console.log(App);
  return (
    <AppLayout {...props}>
      <Row style={{ paddingTop: 100 }} id="home">
        <Col style={{ display: "flex", justifyContent: "center" }}>
          <Image
            src="/img/001v2.jpg"
            width={App.window_width}
            height={App.window_width / 3}
            preview={false}
          />
        </Col>
        {/* <Col style={{ padding: "5px 25px 25px 25px" }}> */}
        <Col span={16} offset={4}>
          <Title level={3}><HomeTitle1 /></Title>
          <Title level={5}><HomeTitle2 /></Title>
          <HomeTitle1Description />
        </Col>
        {/* <Col style={{ display: "flex", justifyContent: "center" }}> */}
        <Col span={16} offset={4}>
          <Image
            src="/img/002v1.jpg"
            // width={App.window_width - 70}
            height={App.window_width / 3}
            preview={false}
          />
        </Col>
        {/* <Col style={{ padding: "5px 25px 0 25px" }}> */}
        <Col span={16} offset={4} style={{ paddingTop: 5 }} id="teams">
          <Title level={5}>
            <HomeTitle2 />
          </Title>
          <HomeDescription2 />
        </Col>
        {/* <Col style={{ padding: "5px 25px 0 25px" }}> */}
        <Col span={16} offset={4} style={{ paddingTop: 5 }}>
          <Title level={5}><TeamsTitle /></Title>
          <TeamsDescription />
        </Col>
        <Col span={16} offset={4} style={{ paddingTop: 5 }}>
          <Title level={5}><PicturesTitle /></Title>
          <Row id="pictures">
            {
              [1, 2, 3, 4, 5, 6].map(i => (
              <Col xs={{span: 20, offset: 4}} sm={{span: 5, offset: 1}}>
                <Image src={`/img/picture00${i}.jpeg`} />
              </Col>
              ))              
            }
          </Row>
        </Col>
        {/* <Col style={{ padding: "5px 25px 0 25px" }}> */}
        <Col span={16} offset={4} style={{ paddingTop: 5 }} id="contact">
          <Title level={5}><ContactTitle /></Title>
          <Space direction="vertical">
            <Text><ContactName /></Text>
            <Text>Cité Cicam - Ancien dépôt des planches - Douala</Text>
            <Divider />
            <Text><ContactDateL1 /></Text>
            <Text><ContactDateL2 /></Text>
            {/* <Text><ContactDateL3 /></Text> */}
            <Divider />
            <Text><ContactPhone /></Text>
            <Text><ContactLocalizationv2><ContactLocalizationv2Value/></ContactLocalizationv2></Text>
          </Space>
        </Col>
      </Row>
    </AppLayout>
  );
};

export default HomePage;
