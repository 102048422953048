import  React from 'react';
import {FormattedMessage} from 'react-intl';

interface ITranslatorProps {
    params?: any;
}
const googleMapUrl: string = "https://www.google.fr/search?tbs=lf:1,lf_ui:2&tbm=lcl&q=kiddy+joy+nursery+douala&rflfq=1&num=10&ved=2ahUKEwj4na2w0OvyAhWuNOwKHQlqDdEQtgN6BAgJEAM#rlfi=hd:;si:5130715132676660901;mv:[[4.058182592185646,9.78497359965976],[4.044098722209823,9.7610268375748],null,[4.051140687846341,9.77300021861728],16]";

export const MenuLanguage: React.FunctionComponent<ITranslatorProps> = (props) => (<FormattedMessage id="menu.language" defaultMessage="Language" description=""/>);
export const MenuHome: React.FunctionComponent<ITranslatorProps> = (props) => (<FormattedMessage id="menu.home" defaultMessage="Home" description=""/>);
export const MenuTeams: React.FunctionComponent<ITranslatorProps> = (props) => (<FormattedMessage id="menu.teams" defaultMessage="Teams" description=""/>);
export const MenuContact: React.FunctionComponent<ITranslatorProps> = (props) => (<FormattedMessage id="menu.contact" defaultMessage="Contact" description=""/>);

export const HomeTitle1: React.FunctionComponent<ITranslatorProps> = (props) => (<FormattedMessage id="home.title1" defaultMessage="La crèche de demain existe enfin." description=""/>);
export const HomeTitle1_2: React.FunctionComponent<ITranslatorProps> = (props) => (<FormattedMessage id="home.title1_1" defaultMessage="Plus qu'une crèche, une prématernelle." description=""/>);
export const HomeTitle1Description: React.FunctionComponent<ITranslatorProps> = (props) => (<FormattedMessage id="home.title1.description" defaultMessage="Groupe scolaire les petits anges n’est pas une simple micro crèche bilingue, c’est une approche inédite qui associe le plus haut niveau de qualité éducative à une offre de service répondant aux besoins des parents les plus exigeants." description=""/>);
export const HomeTitle2: React.FunctionComponent<ITranslatorProps> = (props) => (<FormattedMessage id="home.title2" defaultMessage="Le meilleur pour votre enfant, tout simplement​." description=""/>);
export const HomeDescription2: React.FunctionComponent<ITranslatorProps> = (props) => (<FormattedMessage id="home.title2.description" defaultMessage="Membre de notreGroupe scolaire les petits anges, votre enfant bénéficie d’une approche éducative personnalisée irréprochable grâce à l’encadrement d’une équipe pédagogique et médicale de haut niveau. Tout au long de l’année, il participe à des ateliers encadrés par l’équipe pédagogique et des intervenants extérieurs qui contribuent à révéler le meilleur de lui : initiation musicale, chant, danse, arts plastiques, motricité, sophrologie, langues, sciences" description=""/>);


export const TeamsTitle: React.FunctionComponent<ITranslatorProps> = (props) => (<FormattedMessage id="teams.title" defaultMessage="L'équipe pédagogique." description=""/>);
export const TeamsDescription: React.FunctionComponent<ITranslatorProps> = (props) => (<FormattedMessage id="teams.description" defaultMessage="« Groupe scolaire les petits anges est le lieu d’accueil idéal pour un enfant et ses parents. Nous voulons construire pour et avec l’enfant le socle fondateur d’une conduite sociale faite d’attention, d’adaptation et de compréhension de l’autre. Ce travail doit permettre à l’enfant de s’épanouir tout au long de sa vie et il se fera avec vous ». Dorothée, fondatrice du Groupe scolaire les petits anges." description=""/>);

export const PicturesTitle: React.FunctionComponent<ITranslatorProps> = (props) => (<FormattedMessage id="pictures.title" defaultMessage="Pictures" description=""/>);
export const ContactTitle: React.FunctionComponent<ITranslatorProps> = (props) => (<FormattedMessage id="contact.title" defaultMessage="Contactez-nous." description=""/>);
export const ContactName: React.FunctionComponent<ITranslatorProps> = (props) => (<FormattedMessage id="contact.name" defaultMessage="Groupe scolaire les petits anges" description=""/>);
export const ContactDateL1: React.FunctionComponent<ITranslatorProps> = (props) => (<FormattedMessage id="contact.date.l1" defaultMessage="Ouvert du lundi au vendredi" description=""/>);
export const ContactDateL2: React.FunctionComponent<ITranslatorProps> = (props) => (<FormattedMessage id="contact.date.l2" defaultMessage="de 8h00 à 17h00" description=""/>);
export const ContactDateL3: React.FunctionComponent<ITranslatorProps> = (props) => (<FormattedMessage id="contact.date.l3" defaultMessage="samedi de 8h00 à 15h00" description=""/>);
export const ContactPhone: React.FunctionComponent<ITranslatorProps> = (props) => (<FormattedMessage id="contact.phone" defaultMessage="Appelez-nous au numéro: 655217777, 67304465" description=""/>);
export const ContactLocalization: React.FunctionComponent<ITranslatorProps> = (props) => (<FormattedMessage id="contact.localization" defaultMessage="L'équipe pédagogique." description=""/>);
export const ContactLocalizationv2: React.FunctionComponent<ITranslatorProps> = (props) => (<FormattedMessage id="contact.localization.v2" description=""
        defaultMessage='Cliquer <a>ici</a> pour nous accéder à notre localisation.'
        values={{
            a:() => <a href={googleMapUrl} target="_blank">{props.children}</a>  
        }}
    />);
export const ContactLocalizationv2Value: React.FunctionComponent<ITranslatorProps> = (props) => (<FormattedMessage id="contact.localization.value" description=""
    defaultMessage='ici' 
    />);
