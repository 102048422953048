import React, { useContext, memo, useCallback, useState, useRef, useEffect } from "react";
import { Button, Dropdown, Layout, Menu, Image, Anchor } from "antd";
import { PictureOutlined, DownOutlined, HomeOutlined, ContactsOutlined, TeamOutlined } from "@ant-design/icons";
import LanguageIcon from "@material-ui/icons/Language";
import { default_bck_color } from "../utils/config";
import { MenuContact, MenuHome, MenuLanguage, MenuTeams } from "../translations/Translator";
import { AppActionType, AppContext, IAppState } from "../contexts/AppContextProvider";

interface IHeaderLayoutProps {}

const { Header } = Layout;
const { SubMenu } = Menu;
const { Link } = Anchor;
const HeaderLayout: React.FunctionComponent<IHeaderLayoutProps> = (props) => {

  const homeElt = useRef(null);
  const teamsElt = useRef(null);
  const contactElt = useRef(null);
  const picturesElt = useRef(null);

  const [menuTranslate, setMenuTranslate] = useState<{home: string;teams: string;language: string;}>({home: "Home", teams: "Teams", language: "Language"})
  const {AppState, AppDispatch } = useContext(AppContext);
  const App: IAppState = AppState;


  const handleClick = (e: any) => {
    if(e.key === 'home') (homeElt.current as any).handleClick();
    if(e.key === 'teams') (teamsElt.current as any).handleClick();
    if(e.key === 'contact') (contactElt.current as any).handleClick();
    if(e.key === 'pictures') (picturesElt.current as any).handleClick();
  };

  const handleClickLanguage = (e: any) => {
    AppDispatch({type: AppActionType.UPDATE, payload: {language: e.key}});
    // setMenuTranslate({
    //   home: (e.key === 'en') ? 'Home' : 'Accueil',
    //   teams: (e.key === 'en') ? 'Teams' : 'Equipes',
    //   language: (e.key === 'en') ? 'Language' : 'Langue'
    // })
  };

  const languages = (
    <Menu onClick={handleClickLanguage}>
      <Menu.Item key="en">
        English
      </Menu.Item>
      <Menu.Item key="fr">
        Français
      </Menu.Item>
    </Menu>
  );

  const menu = (
          <Menu onClick={handleClick} selectedKeys={[]} mode="horizontal" style={{backgroundColor: default_bck_color, minWidth: 350}}>
            <Menu.Item key="home" icon={<HomeOutlined />} className="my-menu-item">
              {/* {menuTranslate.home || 'Home'} */}
              {App.language === 'en' ? 'Home' : 'Accueil'}
            </Menu.Item>
            <Menu.Item key="teams" icon={<TeamOutlined />} className="my-menu-item">
              {/* {menuTranslate.teams || 'Teams'} */}
              {App.language === 'en' ? 'Teams' : 'Equipes'}
            </Menu.Item>
            <Menu.Item key="pictures" icon={<PictureOutlined />} className="my-menu-item">
              {App.language === 'en' ? 'Pictures' : 'Photos'}
            </Menu.Item>
            <Menu.Item key="contact" icon={<ContactsOutlined />} className="my-menu-item">
              {'Contact'}
            </Menu.Item>
          </Menu>
  )
  const menuMobile = (
          <Menu onClick={handleClick} selectedKeys={[]} mode="horizontal" style={{backgroundColor: default_bck_color}}>
            <Menu.Item key="home" icon={<HomeOutlined />} className="my-menu-item">
            </Menu.Item>
            <Menu.Item key="teams" icon={<TeamOutlined />} className="my-menu-item">
            </Menu.Item>
            <Menu.Item key="pictures" icon={<PictureOutlined />} className="my-menu-item">
            </Menu.Item>
            <Menu.Item key="contact" icon={<ContactsOutlined />} className="my-menu-item">
            </Menu.Item>
          </Menu>
  )

  return (
    <Header style={{ position: "fixed", zIndex: 1, width: "100%", backgroundColor: default_bck_color }}>
      <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'space-between'}}>
        <div style={{height: 45, paddingTop: 10}}>
          <Image
              src="/img/Kiddy_Joy_Nursery.png"
              // width={App.window_width - 70}
              height={35}
              preview={false}
            />
        </div>
        <div style={{display: "flex"}}>
          {App.window_width > 600 ? menu : menuMobile}
          <div>
            <Dropdown overlay={languages}>
              <Button type="text" size="small" style={{backgroundColor: "#5d262624", fontSize: 12}}>
              {App.language === 'en' ? 'English' : 'Français'} <DownOutlined />
              </Button>
            </Dropdown>
        </div>
        </div>
        {/* -----------anchor ---------- */}
        <Anchor style={{display: 'none'}}>
          <Link href="#home" title="Home" ref={homeElt}/>
          <Link href="#teams" title="Teams" ref={teamsElt}/>
          <Link href="#contact" title="Contact" ref={contactElt}/>
          <Link href="#pictures" title="Pictures" ref={picturesElt}/>
        </Anchor>
      </div>
    </Header>
  );
};

export default memo(HeaderLayout);
