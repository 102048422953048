import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import AppContextProvider from './contexts/AppContextProvider';
import PublicRoutes from './routes/PublicRoutes';
import './App.css';

interface IAppProps {
}

const App: React.FunctionComponent<IAppProps> = (props) => {
  return (<>
  <HelmetProvider>
    <AppContextProvider>
      <PublicRoutes />
    </AppContextProvider>
  </HelmetProvider>
  </>);
};

export default App;
