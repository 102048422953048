import React, { createContext, useReducer, useEffect, useContext  } from 'react';
import { getLanguageFromBrowser } from '../utils/config';

// ( xs < 576  | sm > 576 | md > 768 | lg > 992 | XL > 1200 | xxl > 1600 )
interface IAppContextProviderProps {
}

export enum AppActionType {
    SET_WIDTH_HEIGHT = 'SET_WIDTH_HEIGHT',
    SET_LANGUAGE = 'SET_LANGUAGE',
    UPDATE = 'UPDATE',
    GET_DATA = 'GET_DATA',
}
export interface IAppAction {
    type: string;
    payload: {
        language?: string;
        window_width?: number;
        window_height?: number;
    };
}
export interface IAppState {
    language: string;
    window_width: number;
    window_height: number;
}
export const AppInitState: IAppState = {
    language: getLanguageFromBrowser(),
    window_height: window.innerHeight,
    window_width: window.innerWidth,
}

export const AppContext = createContext<IAppState | any>(AppInitState);

export const reducer = (state: IAppState, action:IAppAction): IAppState => {
    switch(action.type) {
        case AppActionType.UPDATE:
            return {...state, ...action.payload } as IAppState;
        default:
            return state;
    }
}

const AppContextProvider: React.FunctionComponent<any> = (props) => {
    console.log("getLanguageFromBrowser()", getLanguageFromBrowser());
    const [state, dispatch] = useReducer(reducer, AppInitState);

    const updateWindowDimensions = () => {
        dispatch({type: AppActionType.UPDATE, payload: { window_width: window.innerWidth, window_height: window.innerHeight} });
    }

    //-----dispatch async
    const asyncDispatch = async (action:IAppAction) => {
        switch(action.type) {
            case AppActionType.GET_DATA:
                try {                    
                } catch (error) {                    
                }
                break;
            default:
                dispatch(action); 
                break;

        }
    }

    //-----------------------use effect-------------------------------------
    useEffect( () => {
        window.addEventListener('resize', updateWindowDimensions );
        return () => {
            window.removeEventListener('resize', updateWindowDimensions);
        };
    }, [] );
    return (
        <AppContext.Provider value={{AppState: state, AppDispatch: asyncDispatch} }>
            {props.children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;
