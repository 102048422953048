import { Affix, Avatar, Button, Layout } from 'antd';
import React, { ReactNode, useContext, useEffect } from 'react';
import { FacebookFilled } from "@ant-design/icons";
// import Facebook from "@material-ui/icons/Facebook";
import { Helmet } from 'react-helmet-async';
import FooterLayout from './FooterLayout';
import HeaderLayout from './HeaderLayout';
import { default_bck_color } from '../utils/config';
import { AppContext, IAppState } from '../contexts/AppContextProvider';

interface IAppLayoutProps {
    children: ReactNode;
}
const { Content } = Layout;
const AppLayout: React.FunctionComponent<IAppLayoutProps> = ({children}) => {
  const {AppState, AppDispatch } = useContext(AppContext);
  const App: IAppState = AppState;
  const titleEn: string = "KJN | Kiddy Joy Nursery";
  const titleFr: string = "KJN | Groupe scolaire les petits anges";
  const descriptionEn: string = "Crèche Bilingue Douala, Cité Cicam, Cameroun";
  const descriptionFr: string = "bilingual nursery Douala, Cité Cicam, Cameroun";
  const keywordsEn: string = "nursery douala, kiddy joy douala, bilingual school douala, nursery & primary douala";
  const keywordsFr: string = "crèche douala, kiddy joy douala, groupe scolaire bilingue douala, les petits anges, maternel & primaire douala";
  return (
      <Layout>
          {/* =============================================HELMET============================================================== */}
          <Helmet>
                <title>{App.language === 'en' ? titleEn : titleFr}</title>
                <link rel="canonical" href={process.env.REACT_APP_CURRENT_HOST} />
                <meta name="description" content={App.language === 'en' ? descriptionEn : descriptionFr} />
                <meta name="keywords" content={App.language === 'en' ? keywordsEn : keywordsFr} />
          </Helmet>
          {/* ===========header=============== */}
          <HeaderLayout />
          {/* ==========body====================== */}
          <Layout>
              <Content>
                {children}
              </Content>
              {/* ========social network========== */}
              <Affix offsetBottom={125}>
                <Button
                  type='text'
                  onClick={() => {
                    window.open('https://www.facebook.com/groups/497368898077783', '_blank')
                  }}
                  style={{ position: 'absolute', right: 25, backgroundColor: 'transparent', color:default_bck_color }}
                  shape="circle" icon={<FacebookFilled style={{ fontSize: 30}}/>} size="large" />


                {/* <Avatar 
                  style={{ position: 'absolute', right: 25, backgroundColor: default_bck_color, color:'#fff', cursor: 'pointer' }}
                  icon={
                    <FacebookFilled style={{ color: default_bck_color, backgroundColor:'#fff' }} />
                  } 
                  /> */}
              </Affix>
          </Layout>
          {/* ===========header=============== */}
          <FooterLayout />
      </Layout>
  );
};

export default AppLayout;
