import React from 'react';
import {Layout} from 'antd';

interface IFooterLayoutProps {
}

const { Footer } = Layout;
const FooterLayout: React.FunctionComponent<IFooterLayoutProps> = (props) => {
  return (
    <Footer style={{backgroundColor: '#5d656d17'}}>
    <div style={{ textAlign: 'center' }}>
        {/* <span>Aide</span> -&nbsp;
    <span>Informations légales</span> -&nbsp;
    <span>Contact</span> -&nbsp;
    <span>A propos de ship2target</span> */}
    </div>
    <div style={{ textAlign: 'center' }}>&laquo;KIDDY JOY&raquo; Bilingual Daycare</div>
    <div style={{ textAlign: 'center' }}>&copy;{(new Date()).getFullYear()} Created by Fidel</div>
</Footer>
  );
};

export default FooterLayout;
