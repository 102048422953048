import React, { useContext } from 'react';
import { 
  Route, 
  BrowserRouter,
  Switch, 
  // Redirect 
} from 'react-router-dom';
import app_routes from './';
import { IntlProvider } from "react-intl";
import messages_fr from "../translations/fr.json";
import messages_en from "../translations/en.json";
import { AppContext, IAppState } from '../contexts/AppContextProvider';
import HomePage from '../pages/HomePage';


const messages: any = {
  'en': messages_en,
  'fr': messages_fr
};


interface IPublicRoutesProps {
}

const PublicRoutes: React.FunctionComponent<IPublicRoutesProps> = (props) => {
  //------------------usecontext----------
  const {AppState} = useContext(AppContext);
  const App: IAppState = AppState;
  console.log(App);
  return (<BrowserRouter basename='/'>
    <IntlProvider locale={App.language} messages={messages[App.language]}>
      <Switch>
        <Route exact path={app_routes.ROOT} component={HomePage} />
      </Switch>
    </IntlProvider>
  </BrowserRouter>);
};

export default PublicRoutes;
